import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  useMediaQuery,
  DialogTitle,
  CircularProgress,
  Card, CardContent, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import extStyles from '../sections/styles.module.css';
import { Search, TravelExplore, Policy, Insights, Business, PersonSearch, Diversity3, Group, School, Compare } from '@mui/icons-material';
import { getSearchLog, getLoginLog, getStudentsLog, getMembersLog } from '../auth/api';
import {SubjectsStatus, ResultCompare} from './CountAnalysis'



export default function Home() {

  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(true);
  const navigate = useNavigate();
  const [loginLog, setLoginLog] = useState([]);
  const [searchLog, setSearchLog] = useState([]);
  const [studentLog, setStudentLog] = useState([0]);
  const [memberLog, setMemberLog] = useState([0]);
  const [clickLoading, setClickLoading] = useState(false);


  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dialogMinWidth = isSmallScreen ? '90%' : '500px';

  const [openModal, setOpenModal] = useState(false);
  const [openModalCompare, setOpenModalCompare] = useState(false);


  useEffect(() => {
    getLoginLog()
      .then(responseData => {
        setLoginLog(responseData.totalCount);
        setSkeleton(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSkeleton(false);
      });

    getMembersLog()
      .then(responseData => {
        setMemberLog(responseData.totalCount);
        setSkeleton(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSkeleton(false);
      });

    getStudentsLog()
      .then(responseData => {
        setStudentLog(responseData.totalCount);
        setSkeleton(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSkeleton(false);
      });

    getSearchLog()
      .then(responseData => {
        setSearchLog(responseData.totalCount);
        setSkeleton(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSkeleton(false);
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const cardData = [
    { id: 1, title: 'Search', click: '/search', icon: <Search fontSize="large" />, count: <PersonSearch fontSize="large" /> },
    { id: 4, title: 'Analysis', click: '/analysis', icon: <Business fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 2, title: 'Total Members', click: '', icon: <Diversity3 fontSize="large" />, count: memberLog },
    { id: 5, title: 'Total Students', click: '', icon: <Group fontSize="large" />, count: studentLog },
    { id: 6, title: 'Search History', click: '/history', icon: <TravelExplore fontSize="large" />, count: searchLog },
    { id: 3, title: 'Login History', click: '', icon: <Policy fontSize="large" />, count: loginLog },
  ];

  const handleNavigate = (url) => {
    navigate(url);
  }

  const renderPassStatus = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            minHeight: "200px"
          },
        }}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <DialogTitle>Overall Pass Status</DialogTitle>
        <DialogContent>
          {clickLoading ? (
            <div className={extStyles.dialogLoading}>
              <CircularProgress />
            </div>
          ) : (
            <SubjectsStatus/>
          )}
        </DialogContent>
      </Dialog>
    )
  }

  const renderResultCompare = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            minHeight: "200px"
          },
        }}
        open={openModalCompare}
        onClose={() => {
          setOpenModalCompare(false);
        }}
      >
        <DialogTitle>Overall Result Comparison</DialogTitle>
        <DialogContent>
          {clickLoading ? (
            <div className={extStyles.dialogLoading}>
              <CircularProgress />
            </div>
          ) : (
            <ResultCompare/>
          )}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (

          <Grid container spacing={2}>
            {cardData.map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <Card style={{ height: '10rem', cursor: 'pointer' }} onClick={() => handleNavigate(card.click)}>
                  <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ marginBottom: 'auto' }}>
                      {card.icon}
                      <Typography variant="h6">{card.title}</Typography>
                    </div>
                    <Typography variant="h3" className={extStyles.grid_count}>
                      {card.count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={3}>
              <Card style={{ height: '10rem', cursor: 'pointer' }} onClick={() => 
              {
                setOpenModalCompare(true);
              }}
                >
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div style={{ marginBottom: 'auto' }}>
                    <Compare fontSize="large" />
                    <Typography variant="h6">Result Comparison</Typography>
                  </div>
                  <Typography variant="h3" className={extStyles.grid_count}>
                    <Insights fontSize="large" />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <Card style={{ height: '10rem', cursor: 'pointer' }} onClick={() => 
              {
                setOpenModal(true);
              }}
                >
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div style={{ marginBottom: 'auto' }}>
                    <School fontSize="large" />
                    <Typography variant="h6">Pass Status</Typography>
                  </div>
                  <Typography variant="h3" className={extStyles.grid_count}>
                    <Insights fontSize="large" />
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        )}
      </>
      {renderPassStatus()}
      {renderResultCompare()}
    </Container>
  );
}
