
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPagev2 from './pages/LoginPagev2';
import {Page404, HistoryLog} from './pages/Others';
import Search from './pages/HomeSearchv3';
import Home from './pages/HomeGrid';
import { AnalysisMainData, AnalysisResult, StatusResult } from './pages/AnalysisCard';
import {YearsCount, FirmsCount, StudentAnalysisCount, SubjectResults, FirmStatus, SubjectsStatus, FirmResults} from './pages/CountAnalysis';
import ProtectedMainLayout from './auth/ProtectedMainLayout';


export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedMainLayout />}>
        <Route index element={<Navigate to="/home" />} />
        <Route path="home" element={<Home />} />
        <Route path="search" element={<Search />} />
        <Route path="analysis/years-to-chartered-accountants" element={<YearsCount />} />
        <Route path="analysis/firmwise-students" element={<StudentAnalysisCount />}/>
        <Route path="analysis/firmwise-chartered-accountants" element={<FirmsCount />}/>
        <Route path="analysis" element={<AnalysisMainData />} />
        <Route path="analysis/results/subjectwise-results" element={<SubjectResults />} />
        <Route path="analysis/results/overall/subjectwise-status" element={<SubjectsStatus />} />
        <Route path="analysis/results/overall-status" element={<StatusResult />} />
        <Route path="analysis/results/overall/firmwise-status" element={<FirmStatus />} />
        <Route path="analysis/results/firmwise-results" element={<FirmResults />} />
        <Route path="analysis/results" element={<AnalysisResult />} />
        <Route path="history" element={<HistoryLog />} />
      </Route>
      <Route path="login" element={<LoginPagev2 />} />
      <Route path="404" element={<Page404 />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}