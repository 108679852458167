import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  CircularProgress,
  useMediaQuery,
  Card, CardContent, Box, Skeleton, Snackbar, Typography, DialogTitle, DialogContent, Dialog, IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useResponsive from '../sections/UseResponsive';
import extStyles from '../sections/styles.module.css';
// eslint-disable-next-line
import { Clear, Check, TravelExplore, Policy, Insights, School, BusinessCenter, HourglassBottom } from '@mui/icons-material';
import { getYears, getAnalysis, getResultAnalysis, getComparision } from '../auth/api';
import {ResponsiveTable, ResponsiveTableStudent} from '../sections/ResponsiveTable';
import { ResultAnalysisSubject, ResultAnalysisFirm, StatusAnalysisSubject, StatusAnalysisFirm, CompareAnalysisSubject } from '../sections/ResultAnalysis';


export const StudentAnalysisCount = () => {

  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(true);
  const [openSpecificTable, setSpecificTable] = useState(false);
  const [firmNameTitle, setFirmName] = useState([]);
  const [clickLoading, setClickLoading] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const [log, setLog] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dialogMinWidth = isSmallScreen ? '90%' : '500px';


  useEffect(() => {
    getAnalysis()
      .then(responseData => {
        setLog(responseData.distinctValues);
        // console.log(responseData);
        setSkeleton(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setSkeleton(false);
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // const handleNavigate = (url) => {
  //   navigate(url);
  // }

  const handleNavigate = (url) => {
    const requestData = {
      query_type: "specific",
      string: url,
      string_type: "studentslist",
    };

    setClickLoading(true);
    getYears(requestData)
      .then(responseData => {
        setSelectedCardData(responseData);
        setClickLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setClickLoading(false);

      });
  }

  const renderSpecificTable = () => {

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            minHeight: "200px"
          },
        }}
        open={openSpecificTable}
        onClose={() => {
          setSpecificTable(false);
          setSelectedCardData([]);
        }}
      >
        <DialogTitle>{firmNameTitle}</DialogTitle>
        <DialogContent>
          {clickLoading ? (
            <div className={extStyles.dialogLoading}>
              <CircularProgress />
            </div>
          ) : (
            <ResponsiveTableStudent data={selectedCardData}/>
          )}
        </DialogContent>
      </Dialog>
    )
  }


  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            {log && log.length > 0 && log.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.sl}>
                <Card style={{ height: '10rem', cursor: 'pointer' }}
                onClick={() => {
                  handleNavigate(card.value);
                  setFirmName(card.value);
                  setSpecificTable(true);
                }}
                >
                  <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ marginBottom: 'auto' }}>
                      <Typography variant="h6">{card.value}</Typography>
                    </div>
                    <Typography variant="h3" className={extStyles.grid_count}>
                      {card.count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}

          </Grid>
        )}
      </>
      {renderSpecificTable()}
    </Container>
  );
}

export const YearsCount = () => {

  const [loading, setLoading] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [openSpecificTable, setSpecificTable] = useState(false);
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const isDesktop = useResponsive('up', 'lg');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dialogMinWidth = isSmallScreen ? '90%' : '500px';
  const [firmNameTitle, setFirmName] = useState([]);



  useEffect(() => {
    const requestData = {
      query_type: "yearscacount",
    };

    setLoading(true);
    getYears(requestData)
      .then(responseData => {
        setCardData(responseData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);

      });
  }, []);

  const handleNavigate = (url) => {
    const requestData = {
      query_type: "specific",
      string: url,
      string_type: "years",
    };

    setClickLoading(true);
    getYears(requestData)
      .then(responseData => {
        setSelectedCardData(responseData);
        setClickLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setClickLoading(false);

      });
  }

  // eslint-disable-next-line
  const renderSpecificTable = () => {

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            minHeight: "200px"
          },
        }}
        open={openSpecificTable}
        onClose={() => {
          setSpecificTable(false);
          setSelectedCardData([]);
        }}
      >
        <DialogTitle>Years to CA {firmNameTitle}</DialogTitle>
        <DialogContent>
          {clickLoading ? (
            <div className={extStyles.dialogLoading}>
              <CircularProgress />
            </div>
          ) : (
            <ResponsiveTable data={selectedCardData} category="years" />
          )}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (

          <Grid container spacing={2}>
            {cardData.map((card) => (
              <Grid item xs={12} sm={6} md={3} key={card.id}>
                <Card style={{ height: '10rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleNavigate(card.yearRange);
                    setFirmName(card.yearRange);
                    setSpecificTable(true);
                  }}>
                  <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ marginBottom: 'auto' }}>
                      {/* {card.icon} */}
                      <Typography variant="h6">{card.yearRange}</Typography>
                    </div>
                    <Typography variant="h3" className={extStyles.grid_count}>
                      {card.categoryCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </>
      {renderSpecificTable()}
    </Container>
  );
}

export const FirmsCount = () => {

  const [loading, setLoading] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [openSpecificTable, setSpecificTable] = useState(false);
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [firmNameTitle, setFirmName] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const isDesktop = useResponsive('up', 'lg');

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dialogMinWidth = isSmallScreen ? '90%' : '500px';

  useEffect(() => {
    const requestData = {
      query_type: "firmscacount",
    };

    setLoading(true);
    getYears(requestData)
      .then(responseData => {
        setCardData(responseData);
        // console.log(responseData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);

      });
  }, []);

  const handleNavigate = (url) => {
    const requestData = {
      query_type: "specific",
      string: url,
      string_type: "firm",
    };

    setClickLoading(true);
    getYears(requestData)
      .then(responseData => {
        setSelectedCardData(responseData);
        setClickLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setClickLoading(false);

      });
  }

  // eslint-disable-next-line
  const renderSpecificTable = () => {

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            minHeight: "200px"
          },
        }}
        open={openSpecificTable}
        onClose={() => {
          setSpecificTable(false);
          setSelectedCardData([]);
        }}
      >
        <DialogTitle>{firmNameTitle}</DialogTitle>
        <DialogContent>
          {clickLoading ? (
            <div className={extStyles.dialogLoading}>
              <CircularProgress />
            </div>
          ) : (
            <ResponsiveTable data={selectedCardData} category="firm" />
          )}
        </DialogContent>
      </Dialog>
    )
  }


  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (

          <Grid container spacing={2}>
            {cardData.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.id}>
                <Card style={{ height: '10rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleNavigate(card.firmName);
                    setFirmName(card.firmName);
                    setSpecificTable(true);
                  }}
                >
                  <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ marginBottom: 'auto' }}>
                      {/* {card.icon} */}
                      <Typography variant="h6">{card.firmName}</Typography>
                    </div>
                    <Typography variant="h3" className={extStyles.grid_count}>
                      {card.categoryCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </>
      {renderSpecificTable()}
    </Container>
  );
}

export const FirmResults = () => {
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const searchInputRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const removeSearchStates = () => {
    searchInputRef.current.value = '';
    setSearchResult([]);
    setSearchLoading(false);
  }

  const handleSearch = (e) => {
    e.preventDefault();
    const inputValue = searchInputRef.current.value;
    const requestData = {
      type: "firm",
      searchkey: inputValue,
      result: "after_result"
    };
    if (inputValue.trim() === '') {
      setSnackbarMessage("Please enter keywords to search.");
      setSnackbarOpen(true);
    } else {
      setSearchLoading(true);
      getResultAnalysis(requestData)
        .then((response) => {
          setSnackbarOpen(true);
          setSnackbarMessage(response.message);
          setSearchLoading(false);
          const result = response.data;
          setSearchResult(result);
        })
        .catch((error) => {
          console.error(error);
          setSearchLoading(false);
          setSnackbarMessage("An error occurred during the search.");
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={{
              position: 'sticky',
              top: '0',
              background: 'white',
              zIndex: 1,
              padding: '1rem',
              borderRadius: '1rem',
            }}>
              <Box display="flex" alignItems="center">
                <form
                  style={{
                    width: '100%'
                  }}
                  onSubmit={handleSearch}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                    <input
                      autoFocus
                      placeholder='Search firm to see results . . .'
                      style={{
                        padding: '1rem',
                        border: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        outline: 'none',
                        width: '100%', // make the input take the full width
                        paddingRight: '3rem' // space for the adornments
                      }}
                      defaultValue={searchInputRef.current ? searchInputRef.current.value : ''}
                      inputMode="text"
                      name='search'
                      autoComplete="off"
                      ref={searchInputRef}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearch(e);
                          searchInputRef.current && searchInputRef.current.blur();
                        }
                      }}
                    />
                    <div style={{ position: 'absolute', right: '1rem', display: 'flex', alignItems: 'center' }}>
                      {searchInputRef.current && searchInputRef.current.value && (
                        <IconButton
                          style={{
                            cursor: 'pointer',
                            marginRight: '.5rem'
                          }}
                          onClick={removeSearchStates}
                        >
                          <Clear />
                        </IconButton>
                      )}
                      <IconButton
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          handleSearch(e);
                          searchInputRef.current && searchInputRef.current.blur();
                        }}
                        color="primary"
                      >
                        <School />
                      </IconButton>
                    </div>
                  </div>
                </form>
              </Box>
            </div>
            {searchLoading ? (
              <Box sx={{
                width: '100%',
                height: '450px',
                padding: '1rem'
              }}>
                <Skeleton height={('4rem')} animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton height={('3rem')} animation={true} />
                <Skeleton animation='wave' />
                <Skeleton height={('3rem')} animation={false} />
                <Skeleton animation='wave' />
              </Box>
            ) : (
              <ResultAnalysisFirm data={searchResult}></ResultAnalysisFirm>
            )}
          </>
        )
        }
      </>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage.toString()}
      />
    </Container>

  );
}

export const SubjectResults = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestData = {
      type: "subject",
      result: "after_result"
    };
    getResultAnalysis(requestData)
      .then(responseData => {
        setData(responseData.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  // console.log(data);

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <ResultAnalysisSubject data={data}></ResultAnalysisSubject>
        )
        }
      </>
    </Container>

  );
}

export const ResultCompare = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getComparision()
      .then(responseData => {
        setData(responseData.data);
        // console.log("CountAnalysis:",responseData.data)
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  // console.log(data);

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.dialogLoading}>
            <CircularProgress />
          </div>
        ) : (
          <CompareAnalysisSubject data={data}></CompareAnalysisSubject>
        )
        }
      </>
    </Container>

  );
}

export const SubjectsStatus = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestData = {
      type: "subject",
      result: "before_result"
    };
    getResultAnalysis(requestData)
      .then(responseData => {
        setData(responseData.data);
        // console.log("CountAnalysis:",responseData.data)
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  // console.log(data);

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.dialogLoading}>
            <CircularProgress />
          </div>
        ) : (
          <StatusAnalysisSubject data={data}></StatusAnalysisSubject>
        )
        }
      </>
    </Container>

  );
}


export const FirmStatus = () => {
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const searchInputRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const removeSearchStates = () => {
    searchInputRef.current.value = '';
    setSearchResult([]);
    setSearchLoading(false);
  }

  const handleSearch = (e) => {
    e.preventDefault();
    const inputValue = searchInputRef.current.value;
    const requestData = {
      type: "firm",
      searchkey: inputValue,
      result: "before_result"
    };
    if (inputValue.trim() === '') {
      setSnackbarMessage("Please enter keywords to search.");
      setSnackbarOpen(true);
    } else {
      setSearchLoading(true);
      getResultAnalysis(requestData)
        .then((response) => {
          setSnackbarOpen(true);
          setSnackbarMessage(response.message);
          setSearchLoading(false);
          const result = response.data;
          setSearchResult(result);
        })
        .catch((error) => {
          console.error(error);
          setSearchLoading(false);
          setSnackbarMessage("An error occurred during the search.");
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div style={{
              position: 'sticky',
              top: '0',
              background: 'white',
              zIndex: 1,
              padding: '1rem',
              borderRadius: '1rem',
            }}>
              <Box display="flex" alignItems="center">
                <form
                  style={{
                    width: '100%'
                  }}
                  onSubmit={handleSearch}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                    <input
                      autoFocus
                      placeholder='Search firm to see results . . .'
                      style={{
                        padding: '1rem',
                        border: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        outline: 'none',
                        width: '100%', // make the input take the full width
                        paddingRight: '3rem' // space for the adornments
                      }}
                      defaultValue={searchInputRef.current ? searchInputRef.current.value : ''}
                      inputMode="text"
                      name='search'
                      autoComplete="off"
                      ref={searchInputRef}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearch(e);
                          searchInputRef.current && searchInputRef.current.blur();
                        }
                      }}
                    />
                    <div style={{ position: 'absolute', right: '1rem', display: 'flex', alignItems: 'center' }}>
                      {searchInputRef.current && searchInputRef.current.value && (
                        <IconButton
                          style={{
                            cursor: 'pointer',
                            marginRight: '.5rem'
                          }}
                          onClick={removeSearchStates}
                        >
                          <Clear />
                        </IconButton>
                      )}
                      <IconButton
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          handleSearch(e);
                          searchInputRef.current && searchInputRef.current.blur();
                        }}
                        color="primary"
                      >
                        <School />
                      </IconButton>
                    </div>
                  </div>
                </form>
              </Box>
            </div>
            {searchLoading ? (
              <Box sx={{
                width: '100%',
                height: '450px',
                padding: '1rem'
              }}>
                <Skeleton height={('4rem')} animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton height={('3rem')} animation={true} />
                <Skeleton animation='wave' />
                <Skeleton height={('3rem')} animation={false} />
                <Skeleton animation='wave' />
              </Box>
            ) : (
              <StatusAnalysisFirm data={searchResult}></StatusAnalysisFirm>
            )}
          </>
        )
        }
      </>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage.toString()}
      />
    </Container>

  );
}