import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, Typography } from '@mui/material';
import extStyles from '../sections/styles.module.css';


export const ResultAnalysisSubject = ({ data }) => {
    const levels = Object.keys(data);

    return (
        <div>
            {levels.map((level) => (
                <Accordion key={level}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${level}-content`} id={`${level}-header`}>
                        <Typography>{level}</Typography>
                    </AccordionSummary>
                    {Object.keys(data[level]).map((year) => (
                        <Accordion key={year}>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${year}-content`} id={`${year}-header`}>
                                <Typography>{year}</Typography>
                            </AccordionSummary>
                            <div>
                                {Object.keys(data[level][year]).map((session) => (
                                    <TableContainer component={Paper} key={session}>
                                        <Typography sx={{
                                            marginLeft:"15px"
                                        }} variant="h6">{session}</Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Grade</TableCell>
                                                    <TableCell>Total Passed</TableCell>
                                                    <TableCell>Total Appeared</TableCell>
                                                    <TableCell>% Passed</TableCell>
                                                    <TableCell>A</TableCell>
                                                    <TableCell>B</TableCell>
                                                    <TableCell>C</TableCell>
                                                    <TableCell>D</TableCell>
                                                    <TableCell>E</TableCell>
                                                    <TableCell>F</TableCell>
                                                    <TableCell>ep</TableCell>
                                                    <TableCell>ex</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(data[level][year][session]).map((grade) => (
                                                    <TableRow key={grade}>
                                                        <TableCell>{grade}</TableCell>
                                                        {Object.keys(data[level][year][session][grade]).map((type) => (
                                                            <TableCell key={type}>{data[level][year][session][grade][type]}</TableCell>
                                                        ))}
                                        
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                            </div>
                        </Accordion>
                    ))}
                </Accordion>
            ))}
        </div>
    );
};


export const ResultAnalysisFirm = ({ data }) => {
    const levels = Object.keys(data);

    return (
        <div>
          {levels.map((level) => (
            <Accordion key={level}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${level}-content`} id={`${level}-header`}>
                <Typography>{level}</Typography>
              </AccordionSummary>
              {Object.keys(data[level]).map((year) => (
                <Accordion key={year}>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${year}-content`} id={`${year}-header`}>
                    <Typography>{year}</Typography>
                  </AccordionSummary>
                  {Object.keys(data[level][year]).map((session) => (
                    <Accordion key={session}>
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${session}-content`} id={`${session}-header`}>
                        <Typography>{session}</Typography>
                      </AccordionSummary>
                      <div>
                        {Object.keys(data[level][year][session]).map((subject) => (
                          <TableContainer component={Paper} key={subject}>
                            <Typography  sx={{
                                marginLeft:"15px"
                            }} variant="h6">{subject}</Typography>
                            <Table>
                              <TableHead>
                                <TableRow>
                                <TableCell>Grade</TableCell>
                                <TableCell>Total Passed</TableCell>
                                <TableCell>Total Appeared</TableCell>
                                <TableCell>% Passed</TableCell>
                                <TableCell>A</TableCell>
                                <TableCell>B</TableCell>
                                <TableCell>C</TableCell>
                                <TableCell>D</TableCell>
                                <TableCell>E</TableCell>
                                <TableCell>F</TableCell>
                                <TableCell>ep</TableCell>
                                <TableCell>ex</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(data[level][year][session][subject]).map((grade) => (
                                  <TableRow key={grade}>
                                    <TableCell>{grade}</TableCell>
                                    {Object.keys(data[level][year][session][subject][grade]).map((type) => (
                                      <TableCell key={type}>{data[level][year][session][subject][grade][type]}</TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}
                      </div>
                    </Accordion>
                  ))}
                </Accordion>
              ))}
            </Accordion>
          ))}
        </div>
      );
};



export const StatusAnalysisSubject = ({ data }) => {
  const levels = Object.keys(data);
  // console.log("resultAnalysis:",data)

  return (
      <diV>
          {levels.map((level) => (
              <Accordion key={level}>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${level}-content`} id={`${level}-header`}>
                      <Typography>{level}</Typography>
                  </AccordionSummary>
                  {Object.keys(data[level]).map((year) => (
                      <Accordion key={year}>
                          <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${year}-content`} id={`${year}-header`}>
                              <Typography>{year}</Typography>
                          </AccordionSummary>
                          <div>
                              {Object.keys(data[level][year]).map((session) => (
                                  <TableContainer component={Paper} key={session}>
                                      <Typography 
                                      sx={{
                                          marginLeft:"15px"
                                      }} 
                                      variant="h6"
                                      className={extStyles.beforeSession}
                                      >{session}</Typography>
                                      <Table>
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell>Subjects</TableCell>
                                                  <TableCell>Total Passed</TableCell>
                                                  <TableCell>Total Exempted</TableCell>
                                                  <TableCell>Not yet passed</TableCell>
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {Object.keys(data[level][year][session]).map((grade) => (
                                                  <TableRow key={grade}>
                                                      <TableCell>{grade}</TableCell>
                                                      {Object.keys(data[level][year][session][grade]).map((type) => (
                                                          <TableCell key={type}>{data[level][year][session][grade][type]}</TableCell>
                                                      ))}
                                      
                                                  </TableRow>
                                              ))}
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                              ))}
                          </div>
                      </Accordion>
                  ))}
              </Accordion>
          ))}
      </diV>
  );
};

export const CompareAnalysisSubject = ({ data }) => {
  const levels = Object.keys(data);
  // console.log("resultAnalysis:",data)

  return (
      <diV>
          {levels.map((level) => (
              <Accordion key={level}>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${level}-content`} id={`${level}-header`}>
                      <Typography>{level}</Typography>
                  </AccordionSummary>
                  {Object.keys(data[level]).map((year) => (
                      <Accordion key={year}>
                          <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${year}-content`} id={`${year}-header`}>
                              <Typography>{year}</Typography>
                          </AccordionSummary>
                          <div>
                              {Object.keys(data[level][year]).map((session) => (
                                  <TableContainer component={Paper} key={session}>
                                      <Typography 
                                      sx={{
                                          marginLeft:"15px"
                                      }} 
                                      variant="h6"
                                      className={extStyles.beforeSession}
                                      >{session}</Typography>
                                      <Table>
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell>Subjects</TableCell>
                                                  {/* <TableCell>Total Applicants</TableCell> */}
                                                  {/* <TableCell>Ealier Passed</TableCell> */}
                                                  <TableCell>Appeared</TableCell>
                                                  <TableCell>Passed</TableCell>
                                                  {/* <TableCell>Exempted</TableCell> */}
                                                  {/* <TableCell>Not Appeared</TableCell> */}
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {Object.keys(data[level][year][session]).map((grade) => (
                                                  <TableRow key={grade}>
                                                      <TableCell>{grade}</TableCell>
                                                      {Object.keys(data[level][year][session][grade]).map((type) => (
                                                          <TableCell key={type}>{data[level][year][session][grade][type]}</TableCell>
                                                      ))}
                                      
                                                  </TableRow>
                                              ))}
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                              ))}
                          </div>
                      </Accordion>
                  ))}
              </Accordion>
          ))}
      </diV>
  );
};



export const StatusAnalysisFirm = ({ data }) => {
  const levels = Object.keys(data);

  return (
      <div className={extStyles.statusAnalysis}>
        {levels.map((level) => (
          <Accordion key={level}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${level}-content`} id={`${level}-header`}>
              <Typography>{level}</Typography>
            </AccordionSummary>
            {Object.keys(data[level]).map((year) => (
              <Accordion key={year}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${year}-content`} id={`${year}-header`}>
                  <Typography>{year}</Typography>
                </AccordionSummary>
                {Object.keys(data[level][year]).map((session) => (
                  <Accordion key={session}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${session}-content`} id={`${session}-header`}>
                      <Typography>{session}</Typography>
                    </AccordionSummary>
                    <div>
                      {Object.keys(data[level][year][session]).map((subject) => (
                        <TableContainer component={Paper} key={subject}>
                          <Typography  
                          sx={{
                              marginLeft:"15px"
                          }} 
                          variant="h6"
                          className={extStyles.beforeSession}
                          >{subject}</Typography>
                          <Table>
                            <TableHead>
                              <TableRow>
                              <TableCell>Subjects</TableCell>
                                <TableCell>Total Passed</TableCell>
                                <TableCell>Total Exempted</TableCell>
                                <TableCell>Not yet passed</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(data[level][year][session][subject]).map((grade) => (
                                <TableRow key={grade}>
                                  <TableCell>{grade}</TableCell>
                                  {Object.keys(data[level][year][session][subject][grade]).map((type) => (
                                    <TableCell key={type}>{data[level][year][session][subject][grade][type]}</TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ))}
                    </div>
                  </Accordion>
                ))}
              </Accordion>
            ))}
          </Accordion>
        ))}
      </div>
    );
};