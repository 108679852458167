import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { FixedSizeList as FixedList } from "react-window";

import {
  Container,
  Box,
  Snackbar,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  ListItem,
  Paper,
  Grid,
  Dialog,
  DialogContent,
  CircularProgress,
  useMediaQuery,
  FormControlLabel,
  Switch,
  IconButton,
  Skeleton,
} from '@mui/material';
import { Clear, Close, School, BusinessCenter } from '@mui/icons-material';

import useResponsive from '../sections/UseResponsive';
import { searchData } from '../auth/api';
import extStyles from '../sections/styles.module.css';
import DetailsInfoView from '../sections/DetailsView';

DetailsInfoView.propTypes = {
  searchSingle: PropTypes.array.isRequired,
};

export const MemoizedDetailsInfoView = memo(DetailsInfoView);

export default function Home() {
  const searchInputRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [valueClick, setValueClick] = useState(false);
  const [searchArray, setSearchArray] = useState(false);
  const [detailsView, setDetailsView] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchSingle, setSearchSingle] = useState({});
  const isDesktop = useResponsive('up', 'lg');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dialogMinWidth = isSmallScreen ? '90%' : '500px';
  const [searchLoading, setSearchLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [optionSearch, setOptionSearch] = useState(false);
  const [viewLoading, setViewLoading] = useState(true);


  const handleChange = (e) => {
    setOptionSearch(e.target.checked);
    setSearchResult([]);
    setSearchSingle({});
    setDetailsView(false);
    setSearchArray(false);
    setValueClick(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  const handleFilter = () => {
    const inputValue = searchInputRef.current.value.toLowerCase();
    const filteredResult = searchResult.filter((item) => {
      if (optionSearch) {
        return item.userfullname.toLowerCase().includes(inputValue)
      } else {
        return item.name.toLowerCase().includes(inputValue);
      }
    });
    setSearchResult(filteredResult);
  };


  // eslint-disable-next-line
  const handleSearch = (e) => {
    e.preventDefault();
    const inputValue = searchInputRef.current.value;
    const requestData = {
      search: inputValue,
      searchType: 'search',
      searchFrom: optionSearch
    };
    if (inputValue.trim() === '') {
      setSnackbarMessage("Please enter keywords to search.");
      setSnackbarOpen(true);
    } else {
      setSearchLoading(true);
      searchData(requestData)
        .then((response) => {
          setSearchLoading(false);
          const result = response.result;
          // console.log(result);
          if (Array.isArray(result) && result.length > 0) {
            setSearchResult(result);
            setOptionSearch(response.search);
            setSearchArray(true);
          } else {
            setSearchResult([]);
            setSearchArray(false);
            setSnackbarMessage(response.message);
            setSnackbarOpen(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setSearchLoading(false);
          setSnackbarMessage("An error occurred during the search.");
          setSnackbarOpen(true);
        });
    }
  };

  // eslint-disable-next-line
  const handleSearchView = useCallback(debounce((regNo) => {
    const requestData = {
      search: regNo,
      searchType: 'view',
      searchFrom: optionSearch
    };
    searchData(requestData)
      .then((response) => {
        const result = response.result;
        setSearchSingle(result);
        // console.log(response);

        setViewLoading(false);
        const index = searchResult.findIndex(item => (optionSearch ? item.userkeyid : item.regNo) === regNo);
        if (index !== -1) {
          setCurrentIndex(index);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, 300), [searchResult]);

  const handleClose = () => {
    setDetailsView(false);
    setSearchSingle({});
    setValueClick(false);
  }


  const removeSearchStates = () => {
    searchInputRef.current.value = '';
    setSearchResult([]);
    setSearchSingle({});
    setDetailsView(false);
    setSearchArray(false);
    setValueClick(false);
    setSearchLoading(false);
  }

  const Row = useCallback(({ index, style }) => {
    const item = searchResult[index];

    return (
      <div key={optionSearch ? item.userkeyid : item.regNo} style={style} className={extStyles.searchresults}>
        <ListItem>
          <Card
            sx={{
              width: '100%',
              padding: 0,
            }}
            onClick={(e) => {
              e.preventDefault();
              handleSearchView(optionSearch ? item.userkeyid : item.regNo);
              setValueClick(true);
              setSearchSingle({});
              setViewLoading(true);
              isDesktop ? setDetailsView(false) : setDetailsView(true);
            }}
          >
            <CardActionArea>
              <CardContent
                sx={{
                  padding: '1rem',
                }}>
                <Typography variant="p" component="div">
                  {optionSearch ? item.userfullname : item.name} {optionSearch ? '' : '(' + item.regNo + ')'}

                  {
                    <Typography className={extStyles.fNameInfo} variant='p' component="div">
                      {item?.firmName === null ? "Unavailable" : item?.firmName}
                    </Typography>
                  }
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </ListItem>
      </div>
    );
  }, [searchResult, isDesktop, handleSearchView, optionSearch]);


  const renderDetailsDialog = () => {
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          sx: {
            minWidth: dialogMinWidth,
            position: 'relative'
          },
        }}
        open={detailsView}
        onClose={() => setDetailsView(false)}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 999
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
          sx={{
            padding: 0,
            boxShadow: 'none'
          }}
        >
          <MemoizedDetailsInfoView
            searchSingle={searchSingle}
            setCurrentIndex={setCurrentIndex}
            setSearchSingle={setSearchSingle}
            handleSearchView={handleSearchView}
            viewLoading={viewLoading}
            setViewLoading={setViewLoading}
            optionSearch={optionSearch}
            originalSearchResults={searchResult}
            currentIndex={currentIndex}
          />
        </DialogContent>
      </Dialog>
    )
  };

  const LeftSide = () => (
    <div>
      <Paper style={{
        padding: '0',
        borderRadius: '1rem',
        position: 'relative',
      }}>
        <div style={{
          position: 'sticky',
          top: '0',
          background: 'white',
          zIndex: 1,
          padding: '1rem',
          borderRadius: '1rem',
        }}>
          <Box display="flex" alignItems="center">
            <form
              style={{
                width: '100%'
              }}
              onSubmit={handleSearch}>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                <FormControlLabel
                  labelPlacement="start"
                  sx={{
                    margin: 0
                  }}
                  control={
                    <Switch
                      checked={optionSearch}
                      onChange={handleChange}
                      disabled={searchLoading}
                    />
                  }
                />

                <input
                  autoFocus
                  placeholder='Search . . .'
                  style={{
                    padding: '1rem',
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    outline: 'none',
                    width: '100%', // make the input take the full width
                    paddingRight: '3rem' // space for the adornments
                  }}
                  defaultValue={searchInputRef.current ? searchInputRef.current.value : ''}
                  inputMode="text"
                  name='search'
                  autoComplete="off"
                  ref={searchInputRef}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      searchArray ? handleFilter() : handleSearch(e);
                      searchInputRef.current && searchInputRef.current.blur();
                    }
                  }}
                />
                <div style={{ position: 'absolute', right: '1rem', display: 'flex', alignItems: 'center' }}>
                  {searchInputRef.current && searchInputRef.current.value && (
                    <IconButton
                      style={{
                        cursor: 'pointer',
                        marginRight: '.5rem'
                      }}
                      onClick={removeSearchStates}
                    >
                      <Clear />
                    </IconButton>
                  )}
                  <IconButton
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      searchArray ? handleFilter() : handleSearch(e);
                      searchInputRef.current && searchInputRef.current.blur();
                    }}
                    color="primary"
                  >
                    {optionSearch ? <BusinessCenter /> : <School />}
                  </IconButton>
                </div>
              </div>

            </form>
          </Box>
        </div>
        {searchLoading ? (
          <Box sx={{
            width: '100%',
            height: '450px',
            padding: '1rem'
          }}>
            <Skeleton height={('4rem')} animation="wave" />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton height={('3rem')} animation={true} />
            <Skeleton animation='wave' />
            <Skeleton height={('3rem')} animation={false} />
            <Skeleton animation='wave' />
          </Box>
        ) : (
          searchArray ? (
            <FixedList
              height={450}
              itemCount={searchResult.length}
              itemSize={optionSearch ? 54 : 65}
              width={('100%')}
            >
              {Row}
            </FixedList>
          ) : (
            <></>
          )
        )}
      </Paper>
    </div>
  );

  const RightSide = () => (
    <div>
      <Paper style={{
        padding: '0px',
        borderRadius: '1rem',
        background: 'none'
      }}>
        {valueClick ? (
          <MemoizedDetailsInfoView
            searchSingle={searchSingle}
            setCurrentIndex={setCurrentIndex}
            setSearchSingle={setSearchSingle}
            handleSearchView={handleSearchView}
            setViewLoading={setViewLoading}
            viewLoading={viewLoading}
            optionSearch={optionSearch}
            originalSearchResults={searchResult}
            currentIndex={currentIndex}
          />
        ) : (
          <></>
        )}
      </Paper>
    </div>
  );

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} md={12} sm={12}>
              <LeftSide />
            </Grid>
            {isDesktop ? (
              <Grid item xs={12} lg={8} md={12} sm={12}>
                <RightSide />
              </Grid>
            ) : (
              <></>
            )}

          </Grid>
        )}

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage.toString()}
        />
        {!isDesktop ? renderDetailsDialog() : ""}
      </>
    </Container>
  );
}
