import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore, HistoryEdu } from '@mui/icons-material';

const EducationView = ({ edu }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<HistoryEdu />}>
                <b>EDUCATION DETAILS</b>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Degree</TableCell>
                                <TableCell>University/Board</TableCell>
                                <TableCell>Pass Year</TableCell>
                                <TableCell>Group</TableCell>
                                <TableCell>GPA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {edu.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.examName}</TableCell>
                                    <TableCell>{item.boardUni}</TableCell>
                                    <TableCell>{item.passYear}</TableCell>
                                    <TableCell>{item.group_name} ({item.academicLevel})</TableCell>
                                    <TableCell>{item.resultGpa}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default EducationView;
