import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';


export const ResponsiveTable = ({ data, category }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Articleship</TableCell>
              <TableCell>ACA Date</TableCell>
              {/* <TableCell>{(category==="firm") ? "Years to CA" : "Firm Name"}</TableCell> */}
              <TableCell>Firm Name</TableCell>
              <TableCell>Years To CA</TableCell>
              <TableCell>Reg No</TableCell>
              {/* <TableCell>View</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.userfullname}</TableCell>
                <TableCell>{row.periodfrom} to {row.periodto}</TableCell>
                <TableCell>{row.acadate}</TableCell>
                {/* <TableCell>{(category==="firm") ? row.yearRange : row.firmname}</TableCell> */}
                <TableCell>{row.firmname}</TableCell>
                <TableCell>{row.yearRange}</TableCell>
                <TableCell>{row.regno}</TableCell>
                {/* <TableCell><IconButton onClick={()=>{

                }}><RemoveRedEyeRounded/></IconButton></TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export const ResponsiveTableStudent = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Articleship</TableCell>
              <TableCell>RegNo</TableCell>
              <TableCell>RegYear</TableCell>
              <TableCell>Principal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.periodFrom} <i>to</i> {row.periodTo}</TableCell>
                <TableCell>{row.regNo}</TableCell>
                <TableCell>{row.regYear}</TableCell>
                <TableCell>{row.prinName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};