import React, { useState, useEffect} from 'react';
import {
  Container,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import extStyles from '../sections/styles.module.css';
import { History, Search, Check, TravelExplore, AutoStories, Insights, School, Business, BusinessCenter } from '@mui/icons-material';
import GridView from '../sections/GridView';

export const AnalysisMainData = () => {

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const mainData = [
    { id: 3, title: 'Firmwise CAs', click: '/analysis/firmwise-chartered-accountants', icon: <BusinessCenter fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 4, title: 'Firmwise Students', click: '/analysis/firmwise-students', icon: <Business fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 5, title: 'Years to CAs', click: '/analysis/years-to-chartered-accountants', icon: <BusinessCenter fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 5, title: 'Results', click: '/analysis/results', icon: <School fontSize="large" />, count: <Insights fontSize="large" /> },
  ];

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <GridView cardData={mainData} handleNavigate={handleNavigate}></GridView>
        )}
      </>
    </Container>
  );
}


export const AnalysisResult = () => {

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const mainData = [
    { id: 2, title: 'Firmwise Results', click: '/analysis/results/firmwise-results', icon: <Business fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 1, title: 'Subjectwise Results', click: '/analysis/results/subjectwise-results', icon: <School fontSize="large" />, count: <Insights fontSize="large" /> },
    // { id: 3, title: 'Overall Status', click: '/analysis/results/overall-status', icon: <AutoStories fontSize="large" />, count: <Insights fontSize="large" /> },
  ];

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <GridView cardData={mainData} handleNavigate={handleNavigate}></GridView>
        )}
      </>
    </Container>
  );
}

export const StatusResult = () => {

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const mainData = [
    { id: 2, title: 'Firmwise Status', click: '/analysis/results/overall/firmwise-status', icon: <Business fontSize="large" />, count: <Insights fontSize="large" /> },
    { id: 1, title: 'Subjectwise Status', click: '/analysis/results/overall/subjectwise-status', icon: <School fontSize="large" />, count: <Insights fontSize="large" /> },
  ];

  return (
    <Container maxWidth="xl">
      <>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <GridView cardData={mainData} handleNavigate={handleNavigate}></GridView>
        )}
      </>
    </Container>
  );
}
