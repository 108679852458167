import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Container, TableBody, TableRow, Snackbar, Table, TableContainer, TableCell, TableHead, List, ListItem, ListItemText, TextField, InputAdornment, Paper, Grid, FormControl, InputLabel, Input, Select, MenuItem, Dialog, Button, DialogContent, DialogTitle, CircularProgress, Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getSearchLog } from '../auth/api';
import extStyles from '../sections/styles.module.css';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  textAlign: "center",
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

export const Page404 = () => {

  return (
    <>
      <StyledContent>
        <Container>
          <Typography style={{ marginBottom: '2rem' }} variant="h2">
            ERROR 404
          </Typography>
          <Button to="/" size="small" component={RouterLink}>
            Go Home
          </Button>
        </Container>
      </StyledContent>
    </>
  );
}

export const HistoryLog = () => {

  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [searchLog, setSearchLog] = useState([]);

  useEffect(() => {
    getSearchLog()
      .then(responseData => {
        setSearchLog(responseData.result || []); // Ensure searchLog is always an array
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
        setSearchLog([]); // Set searchLog to an empty array in case of an error
        setSnackbarOpen(true);
        setSnackbarMessage('Error fetching data.'); // Display a message in case of an error.
      });
  }, []);

  console.log("dataLength:", searchLog.length);

  return (
    <Container maxWidth="xl">
      {loading ? (
        <div className={extStyles.spinnerarea}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {searchLog.length === 0 ? (
            <div>No history log found</div>
          ) : (
            <>
              {searchLog.map((item, index) => (
                <div style={{
                  margin: '.5rem 0rem'
                }} key={item.id}>
                  <Typography>
                    [{item.date_search}] - <strong>{item.search_type === 'view' ? "[View] " : "[Search] "} </strong> {item.keywords}; <strong>[Found] </strong>{item.found};<strong> [User] </strong>{item.username}
                  </Typography>
                  <Divider sx={{
                    margin: '.5rem 0rem'
                  }} />
                </div>

              ))}
            </>
          )}
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage.toString()}
      />
    </Container>

  );
}
