import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ResultGrade from './ResultGrade'
import { properCase } from './CommonFunction';

const ResultViewTree = ({ data }) => {
    if (!data || !data.result) {
        console.log('No data or incorrect data structure');
        return null;
    }

    const levels = Object.keys(data.result);

    return (
        <div>
            {levels.map((level) => (
                <Accordion key={level}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                        <Typography sx={{
                            color: "#7bd389ff",
                            fontWeight: "700"
                        }}>{level}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {Object.keys(data.result[level]).map((year) => (
                            <Accordion key={year}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                                    <Typography sx={{
                                        color: "#aedcc0ff",
                                        fontWeight: "700"
                                    }}>{year}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {Object.entries(data.result[level][year]).map(([session, sessionData]) => (
                                        <Accordion key={session}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                                                <Typography sx={{
                                                    color: "#607466ff",
                                                    fontWeight: "700"
                                                }}>{session}</Typography>
                                            </AccordionSummary>

                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Subject</TableCell>
                                                            {/* <TableCell>Before Result</TableCell> */}
                                                            <TableCell>Result</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Array.from({
                                                            length: Math.max(
                                                                (sessionData.before_result ?? []).length,
                                                                (sessionData.after_result ?? []).length
                                                            )
                                                        }).map((_, index) => {
                                                            const subjectAppeared = sessionData.before_result?.[index]?.appeared || sessionData.after_result?.[index]?.appeared;

                                                            const subjectName = (sessionData.before_result?.[index] || sessionData.after_result?.[index])?.subName;

                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell style={{ 
                                                                        color: subjectAppeared ? '#007bff' : 'black',
                                                                        fontWeight: subjectAppeared ? 'bold' : ''
                                                                        }}>
                                                                        {subjectName}
                                                                    </TableCell>
                                                                    {/* <TableCell>
                                                                        {sessionData.before_result?.[index]?.grade ?? 'Not yet passed'}
                                                                    </TableCell> */}
                                                                    <TableCell>
                                                                        {sessionData.after_result?.[index]?.grade ?? 'No history'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>


                                                </Table>
                                            </TableContainer>
                                        </Accordion>
                                    ))}
                                </AccordionDetails>

                            </Accordion>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};


export default ResultViewTree;
