import React from 'react';
import { Grid,Card,CardContent,Typography, } from '@mui/material';
import extStyles from '../sections/styles.module.css';


const GridView = ({ cardData, handleNavigate }) => {

    return (
        <Grid container spacing={2}>
            {cardData.map((card) => (
                <Grid item xs={12} sm={6} md={3} key={card.id}>
                    <Card style={{ height: '10rem', cursor: 'pointer' }} 
                    onClick={() => 
                        {
                            handleNavigate(card.click)
                        }
                    }>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div style={{ marginBottom: 'auto' }}>
                                {card.icon}
                                <Typography variant="h6">{card.title}</Typography>
                            </div>
                            <Typography variant="h3" className={extStyles.grid_count}>
                                {card.count}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>

    );
};

export default GridView;