import React, { useState, useCallback } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { debounce } from 'lodash';


import {
    Table, TableBody, TableCell, TableContainer, TableRow,
    Box,
    Card,
    CardContent,
    Paper,
    Grid,
    CircularProgress,
    IconButton,
    Skeleton,
    Accordion, AccordionSummary, AccordionDetails,
    Divider, Switch
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, School,ExpandMore, Nightlife, RecentActors } from '@mui/icons-material';
import extStyles from '../sections/styles.module.css';
import { searchData, getResult } from '../auth/api';
import EducationView from '../sections/EducationView';
import ResultView from '../sections/ResultView_v2';
import useResponsive from '../sections/UseResponsive';


const DetailsView = ({
    originalSearchResults,
    setSearchSingle,
    handleSearchView,
    setCurrentIndex,
    viewLoading,
    setViewLoading,
    currentIndex,
    searchSingle,
    optionSearch,
}) => {


    const imagePath = process.env.PUBLIC_URL + '/assets/android-chrome-192x192.png';
    const [imageUrl, setImageUrl] = useState(imagePath);
    const [imageView, setImageView] = useState(false);
    const [eduResult, setEduResult] = useState(null);
    const [viewEduInfoLoading, setViewEduInfoLoading] = useState(false);
    const [viewEduInfo, setViewEduInfo] = useState(false);
    const [allResult, setResult] = useState([]);
    const isDesktop = useResponsive('up', 'lg');

    const item = searchSingle;
    const handleImgError = useCallback((e) => {
        e.preventDefault();
        e.target.src = imagePath;
        e.target.onerror = null;
    }, [imagePath]);

    const handleImgViewToggle = () => {
        setImageView(!imageView);
    };

    const handleEducationHistory = useCallback(
        debounce((regNo) => {

        setViewEduInfoLoading(true);

        const requestData = {
            search: regNo,
            searchType: 'edu',
            searchFrom: optionSearch
        };
        searchData(requestData)
            .then((response) => {
                const result = response.result;
                setEduResult(result);
                setViewEduInfoLoading(false);
                setViewEduInfo(true);
            })
            .catch((error) => {
                console.error(error);
                setViewEduInfoLoading(false);
                setViewEduInfo(false);
            });
    }, 300), [optionSearch]);

    const handleResult = (reg_no) => {

        const requestData = {
            reg_no: reg_no
        }

        getResult(requestData)
            .then((response) => {
                setResult(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handlePrev = () => {
        let newIndex;
        if (currentIndex === 0) {
            newIndex = originalSearchResults.length - 1; // go to the last item
        } else {
            newIndex = currentIndex - 1; // go to the previous item
        }

        const regNo = originalSearchResults[newIndex].regNo;
        const userkeyid = originalSearchResults[newIndex].userkeyid;
        handleSearchView(optionSearch ? userkeyid : regNo);
        setCurrentIndex(newIndex);
        setSearchSingle({});
        setViewEduInfo(false);
        setImageUrl(imagePath);
        setViewLoading(true);
    };

    const handleNext = () => {
        let newIndex;
        if (currentIndex === originalSearchResults.length - 1) {
            newIndex = 0; // go to the first item
        } else {
            newIndex = currentIndex + 1; // go to the next item
        }
        const regNo = originalSearchResults[newIndex].regNo;
        const userkeyid = originalSearchResults[newIndex].userkeyid;
        handleSearchView(optionSearch ? userkeyid : regNo);
        setCurrentIndex(newIndex);
        setSearchSingle({});
        setViewEduInfo(false);
        setViewLoading(true);
        setImageUrl(imagePath);
    };

    const acaDateCalc = (acaDate, articleDate) => {
        const acaDateObj = new Date(acaDate);
        const articleDateObj = new Date(articleDate);

        if (isNaN(acaDateObj) || isNaN(articleDateObj)) {
            return 'Invalid dates';
        }

        const differenceDays = differenceInCalendarDays(acaDateObj, articleDateObj);
        if (isNaN(differenceDays)) {
            return 'Invalid date difference';
        }

        const years = Math.floor(differenceDays / 365);
        const months = Math.floor((differenceDays % 365) / 30);
        const days = differenceDays % 30;

        return `${years} years, ${months} months, and ${days} days`;
    };




    if (!viewLoading) {
        return (
            <div style={{
                backgroundColor: 'none',
            }}>

                <Card
                    key={item?.id}
                    style={{
                        borderRadius: isDesktop ? '1rem' : '0'

                    }}
                >
                    <CardContent style={{
                        display: 'flex',
                        alignItems: isDesktop ? 'flex-start' : '',
                        flexDirection: isDesktop ? 'row' : 'column',
                        minHeight: '10rem',
                    }}>
                        <div className={extStyles.imageWrap}>
                            <div style={{
                                marginRight: isDesktop ? '1rem' : '',
                                marginBottom: isDesktop ? '' : '1rem',
                                display: 'flex',
                                alignItems: isDesktop ? 'flex-start' : 'center',
                                flexDirection: isDesktop ? 'row' : 'column',
                                minHeight: isDesktop ? '' : '180px',
                            }}>

                                {
                                    imageView ? (
                                        <img
                                            src={
                                                item?.photo_inserted === '0' || item?.photo_inserted === '2' ?
                                                    optionSearch ?
                                                        (item.memberimg === null ?
                                                            imageUrl :
                                                            item.srvhost + '' + item.memberimg) :
                                                        (item.imagepath === null ?
                                                            imageUrl :
                                                            item.imagepath) :
                                                    `data:image/jpeg;base64,${item?.photo}`}
                                            alt={`Profile of ${optionSearch ? item?.fName : item?.name}`}
                                            style={{ borderRadius: '.5rem', width: optionSearch ? isDesktop ? '12rem' : '12rem' : '9rem', height: '100%', objectFit: 'cover' }}
                                            onError={handleImgError}
                                        />
                                    ) : (
                                        <img
                                            src={imageUrl}
                                            alt={`Profile of ${optionSearch ? item?.fName : item?.name}`}
                                            style={{ borderRadius: '.5rem', width: optionSearch ? isDesktop ? '12rem' : '12rem' : '9rem', height: '100%', objectFit: 'cover' }}
                                            onError={handleImgError}
                                        />
                                    )
                                }

                            </div>

                        </div>

                        <div style={{
                            width: '100%'
                        }}>
                            {
                                optionSearch ? (
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTd_name}>{item?.userfullname}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTd_title}>{item?.userposition}, {item?.userorgname}</TableCell>
                                                    </TableRow>
                                                    {(optionSearch && item.regNo) || (!optionSearch && item.userkeyid) ? (
                                                        <TableRow>
                                                            {/* <TableCell className={`${extStyles.tableTh} ${extStyles.tableSp}`}>Years to qualify CA</TableCell> */}
                                                            <TableCell className={`${extStyles.tableTd} ${extStyles.tableSpV}`}>
                                                                Qualifying as a Chartered Accountant took approximately  <b>{acaDateCalc(item.acadate, item.periodFrom)}</b>.
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ) : (
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell rowSpan={2} className={extStyles.tableTd_name}>{item.name} ({item.regNo}/{item.regYear})</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                            <Box m={3}/>

                                            <Table>
                                                <TableBody>
                                                
                                                {!item?.cell ? "" : (
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTh}>Mobile </TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.cell}</TableCell>
                                                    </TableRow>
                                                )}

                                                {!item?.email ? "" : (
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTh}>Email </TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.email}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.dob ? "" : (
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTh}>Birthday </TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.dob}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.fName ? "" : (
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTh}>Father Name </TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.fName}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.mName ? "" : (
                                                    <TableRow >
                                                        <TableCell className={extStyles.tableTh}>Mother Name </TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.mName}</TableCell>
                                                    </TableRow>
                                                )}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )
                            }
                            <div className={extStyles.buttonArea}>
                                <div className={extStyles.eduButton}>
                                {
                                    !optionSearch ? (
                                        <>
                                            <IconButton
                                                color="success"
                                                onClick={() => {
                                                    setViewEduInfoLoading(true);
                                                    handleEducationHistory(item.regNo);
                                                    handleResult(item.regNo);
                                                }}
                                            >
                                                <School />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                                </div>
                                
                                <div className={extStyles.imgToggle}>
                                    <Switch
                                        checked={imageView}
                                        onChange={handleImgViewToggle}
                                        inputProps={{ 'aria-label': 'toggle switch' }}
                                    />
                            </div>
                            </div>
                        </div>
                    </CardContent>

                    <Divider sx={{}} />
                    {/* <CardContent style={{
                        // display: 'flex',
                        // alignItems: isDesktop ? 'flex-start' : '',
                        // flexDirection: isDesktop ? 'row' : 'column',
                        minHeight: '12rem',
                    }}> */}
                        <Accordion>
                            <AccordionSummary expandIcon={<RecentActors />}>
                                <b>PERSONAL DETAILS</b>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                            optionSearch ? (

                                <div>

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableBody>
                                                {!item?.firmName ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Article Firm</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.firmName} ({item?.periodFrom} <b>to</b> {item?.periodTo})</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.prinName ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Principal</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.prinName}</TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>ACA Date</TableCell>
                                                    <TableCell className={extStyles.tableTd}>
                                                        {item?.acadate}
                                                    </TableCell>
                                                </TableRow>
                                                {!item?.fcadate ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>FCA Date</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.fcadate}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.fName ? "" : (

                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Father</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.fName}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.mName ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Mother</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.mName}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.dob ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Birthday</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.dob}</TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Mobile</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item?.cellphone1}, {item?.cellphone2}</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Email</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item?.emailid1}, {item?.emailid2}</TableCell>
                                                </TableRow>

                                                {!item?.educationinfo ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Graduation</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item?.educationinfo}</TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Membership</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item?.membershipstatus} ({item?.occupationalcategory})</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Address</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item?.useraddress} </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            ) : (

                                <div>

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableBody>
                                                {(optionSearch && item.regNo) || (!optionSearch && item.userkeyid) ? (
                                                    <TableRow>
                                                        <TableCell className={`${extStyles.tableTh} ${extStyles.tableSp}`}>Years to qualify CA</TableCell>
                                                        <TableCell className={`${extStyles.tableTd} ${extStyles.tableSpV}`}>
                                                            {acaDateCalc(item.acadate, item.periodFrom)} (approximate)
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null}
                                                {!item?.acadate ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>ACA Date</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.acadate}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.userorgname ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Career</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.userposition}, {item.userorgname}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.periodFrom ? "" : (
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Article Period</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item.periodFrom} to {item.periodTo}</TableCell>
                                                </TableRow>
                                                )}
                                                {!item?.preAdd ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Present Address</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.preAdd}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.perAdd ? "" : (
                                                    <TableRow>
                                                        <TableCell className={extStyles.tableTh}>Permanent Address</TableCell>
                                                        <TableCell className={extStyles.tableTd}>{item.perAdd}</TableCell>
                                                    </TableRow>
                                                )}
                                                {!item?.firmName ? "" : (
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Firm Name</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item.firmName}</TableCell>
                                                </TableRow>
                                                )}
                                                {!item?.prinName ? "" : (
                                                <TableRow>
                                                    <TableCell className={extStyles.tableTh}>Principal Name</TableCell>
                                                    <TableCell className={extStyles.tableTd}>{item.prinName} {item.prinEnrNo === '0' ? '' : '(' + item.prinEnrNo + ')'}</TableCell>
                                                </TableRow>
                                                )}
                                                </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            
                            )
                        }

                            </AccordionDetails>
                            </Accordion>
                        
                    {/* </CardContent> */}

                    {
                        viewEduInfo ? (
                            viewEduInfoLoading ? (
                                <>
                                    <div className={extStyles.eduSpinner}>
                                        <CircularProgress />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <EducationView edu={eduResult}></EducationView>
                                        <Divider sx={{}} />
                                    <ResultView data={allResult}></ResultView>
                                </>
                            )
                        ) : null
                    }
                    {(optionSearch && item.regNo) || (!optionSearch && item.userkeyid) ? (
                        <>  
                            <Divider sx={{}} />

                            <div className={extStyles.enrollment_no}>
                                <b>{optionSearch ? "Registration Number:  " : "Enrollment Number:  "}</b>
                                {optionSearch ? item.regNo : item.userkeyid}
                            </div>
                        </>
                    ) : null}
                    
                    <Divider sx={{}} />
     
                    <div className={extStyles.arrow_container}>
                        <IconButton className={extStyles.left_arrow}
                                variant="contained"
                                color="primary"
                                onClick={handlePrev}
                            >
                                <ArrowBackIos />
                            </IconButton>

                            <IconButton
                            className={extStyles.right_arrow}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                <ArrowForwardIos />
                            </IconButton>
                    </div>
                </Card>


            </div>
        );
    } else {
        return <div className={extStyles.spinnerarea_deatails}><CircularProgress /></div>;
    }
}

export default DetailsView;